window.matchMedia || (window.matchMedia = function (media) {
	return {
		matches: window.media.matchMedium(media || 'all'),
		media: media || 'all'
	};
});
(function ($) {
    window.UPVD = function UPVD() {
    };
    UPVD.small = "740px";
    UPVD.medium = "850px";
    UPVD.large = "1060px";
    UPVD.xlarge = "1280px";
    UPVD.breakpoint = UPVD.small;
    UPVD.auMoinsMediumValue = true;
    UPVD.auMoinsSmallValue = true;
    UPVD.isHeadPageVisible = false; // Is the header frame visible
    /**
     * Determine if "haut_page" should be visible according to the
     * "window.scrollTop" value
     */
    UPVD.handleButton = function () {
        var $window = $(window);
        var $hautPage = $('.retour-haut');
        var criticalPoint = $('header').outerHeight(true);
        if ($window.scrollTop() > criticalPoint) {
            if (!UPVD.isHeadPageVisible) {
                $hautPage.transition({
                    y: "-=100",
                    opacity: "1"
                });
                UPVD.isHeadPageVisible = true;
            }
        }
        if ($window.scrollTop() > 0 && $window.scrollTop() < criticalPoint) {
            if (UPVD.isHeadPageVisible) {
                $hautPage.transition({
                    y: "+=100",
                    opacity: "0"
                });
                UPVD.isHeadPageVisible = false;
            }
        }
    };
    // Triggered when the window is scrolled
    UPVD.onWindowScroll = function () {
        UPVD.handleButton();
    };
    /**
     * Adapter les textes de type shrink
     */
    UPVD.adapterTexte = function () {
        /*
         * $(".shrink").each(function() { $(this).css("font-size","50px"); var
         * targetHeight = $(this).clone().html("&nbsp;").insertAfter($(this));
         * while ($(this).height() > targetHeight.height()) {
         * $(this).css("font-size", parseInt($(this).css("font-size")) - 1);
         * targetHeight.remove(); targetHeight =
         * $(this).clone().html("&nbsp;").insertAfter($(this)); }
         * targetHeight.remove(); });
         */
    };
    /**
     * Vérification du media
     *
     * @param mediaVal
     *            Valeur de média, en em / px,...
     * @returns Vrai si au moins >= valeur de media
     */
    UPVD._matchMedia = function (mediaVal) {
        var media = 'screen and (min-width: ' + mediaVal + ')';
        var mediaMatch = matchMedia(media).matches;
        return mediaMatch;
    };
    /**
     * Media Query au moins small
     *
     * @returns vrai si >= small
     */
    UPVD.auMoinsSmall = function () {
        return UPVD._matchMedia(UPVD.small);
    };
    /**
     * Media Query au moins medium
     *
     * @returns vrai si >= medium
     */
    UPVD.auMoinsMedium = function () {
        return UPVD._matchMedia(UPVD.medium);
    };
    /**
     * Media Query au moins Large
     *
     * @returns vrai si >= large
     */
    UPVD.auMoinsLarge = function () {
        return UPVD._matchMedia(UPVD.large);
    };
    /**
     * Media Query au moins X-Large
     *
     * @returns vrai si >= x-large
     */
    UPVD.auMoinsXLarge = function () {
        return UPVD._matchMedia(UPVD.xlarge);
    };
    /**
     * Habillage de la page en responsive
     */
    UPVD.habillerResponsive = function () {
        var elementsSmallAModifier = $('main .plier-deplier-2__contenu');
        var auMoinsSmall = UPVD.auMoinsSmall();
        if (!auMoinsSmall) {
            // Si l'état a changé, on ferme, sinon, on ne fait rien (on garde
            // l'état)
            if (UPVD.auMoinsSmallValue != auMoinsSmall) {
                elementsSmallAModifier.removeClass('plier-deplier-2__contenu--ouvert').addClass('plier-deplier-2__contenu--clos');
            }
        } else {
            // On ouvre en desktop dans tous les cas
            elementsSmallAModifier.removeClass('plier-deplier-2__contenu--clos').addClass('plier-deplier-2__contenu--ouvert');
        }
        UPVD.auMoinsSmallValue = auMoinsSmall;
        // Rubriques Footer
        var elementsMediumAModifier = $('footer .plier-deplier-2__contenu');
        var auMoinsMedium = UPVD.auMoinsMedium();
        if (!auMoinsMedium) {
            // Si l'état a changé, on ferme, sinon, on ne fait rien (on garde
            // l'état)
            if (UPVD.auMoinsMediumValue != auMoinsMedium) {
                elementsMediumAModifier.removeClass(
                    'plier-deplier-2__contenu--ouvert').addClass(
                    'plier-deplier-2__contenu--clos');
            }
        } else {
            // On ouvre en desktop dans tous les cas
            elementsMediumAModifier.removeClass('plier-deplier-2__contenu--clos').addClass('plier-deplier-2__contenu--ouvert');
        }
        UPVD.auMoinsMediumValue = auMoinsMedium;
    };
    /**
     * Inititalisation du bouton play / pause des carrousels intérieurs
     */
    UPVD.initialiserPausePlayCarrousel = function () {
        var carrouselsBouton = $(".pause_play[data-cycle-context^='#cycle_']");
        carrouselsBouton.each(function (index, element) {
            var idCycle = $(this).attr("data-cycle-context");
            var cycle = $(idCycle);
            var bouton = $(this);
            // Ajout au click sur le boutons play/pause des carrousels
            $(this).click(function () {
                if (cycle.is('.cycle-paused')) {
                    cycle.cycle('resume');
                } else {
                    cycle.cycle('pause');
                }
            });
            cycle.on('cycle-paused', function (e, optionHash) {
                bouton.removeClass('icon-pause2').addClass('icon-play3');
            });
            cycle.on('cycle-resumed', function (e, optionHash) {
                bouton.removeClass('icon-play3').addClass('icon-pause2');
            });
        });
    };
    UPVD.onResize = function () {
        UPVD.habillerResponsive();
        UPVD.adapterTexte();
    };
    UPVD.onInitialisation = function () {
        UPVD.habillerResponsive();
        UPVD.adapterTexte();
        UPVD.numberShowAnimation();
    };
    UPVD.throttle = function (func, wait, options) {
        var context, args, result;
        var timeout = null;
        var previous = 0;
        if (!options) {
            options = {};
        }
        var later = function () {
            previous = options.leading === false ? 0 : Date.now();
            timeout = null;
            result = func.apply(context, args);
            if (!timeout) {
                context = args = null;
            }
        };
        return function () {
            var now = Date.now();
            if (!previous && options.leading === false) {
                previous = now;
            }
            var remaining = wait - (now - previous);
            context = this;
            args = arguments;
            if (remaining <= 0 || remaining > wait) {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                previous = now;
                result = func.apply(context, args);
                if (!timeout) {
                    context = args = null;
                }
            } else if (!timeout && options.trailing !== false) {
                timeout = setTimeout(later, remaining);
            }
            return result;
        };
    };
    /**
     * Activer l'animation de défilement des chiffres clés.
     */
    UPVD.numberShowAnimation = function() {
        var numberTargetAttribute = 'data-number-target';
        var duration = 3000;
        var numbers = document.querySelectorAll('.js-number-animation');
        var startDate;
        var endDate;
        var scrollListener;

        // Initialise les événements de déclenchement de l'animation si des "chiffres" sont compatibles
        function init() {
            // On remplace la virgule par un point pour pouvoir le traiter comme tel
            numbers.forEach(function (p) {
                var value = p.getAttribute(numberTargetAttribute);
                if (value) {
                    p.setAttribute(numberTargetAttribute, value.replace(',', '.'));
                }
            });
            // On ne traite que les nombres
            numbers = [].filter.call(numbers, function (p) {
                var value = p.getAttribute(numberTargetAttribute);
                if (!value || value.indexOf('.') !== -1) {
                    return false;
                }
                return !isNaN(1 * value);
            });
            if (numbers.length <= 0) {
                return;
            }
            scrollListener = UPVD.throttle(animationScrollSpy, 100);
            window.addEventListener('scroll', scrollListener);
            animationScrollSpy();
        }

        /**
         * Vérifie que le premier chiffre clé est visible à l'écran.
         * On souhaite en effet que tous les chiffres défilent en même temps.
         */
        function animationScrollSpy() {
            var currentHeight = window.innerHeight || 0;
            var currentPosition = document.documentElement.scrollTop || document.body.scrollTop || 0;
            // Si le nombre apparaît à l'écran
            if (getOffsetTop(numbers[0]) < currentPosition + currentHeight + 40) {
                // On lance l'animation
                startAnimation();
                // On arrête d'écouter le scroll car l'animation ne doit être lancée qu'une seule fois
                window.removeEventListener('scroll', scrollListener);
                // Il n'y a plus de listener enregistré, on l'abandonne
                scrollListener = null;
            }
        }

        /**
         * Démarre l'animation de défilement.
         */
        function startAnimation() {
            startDate = +new Date();
            endDate = startDate + duration;
            animationLoop();
        }

        /**
         * Étape de la boucle d'animation.
         */
        function animationLoop() {
            var currentDate = +new Date();
            if (currentDate >= endDate) {
                onEnd();
            } else {
                onProgress(1 - (endDate - currentDate) / duration);
                requestAnimationFrame(animationLoop);
            }
        }

        /**
         * Lancé par la boucle d'animation si l'animation est dans un état intermédiaire
         * @param {number} progress La progression de l'animation (0 <= progress < 1)
         */
        function onProgress(progress) {
            var ease = easeOutQuint(progress);
            numbers.forEach(function (item) {
                item.textContent = thousands(Math.floor(ease * item.getAttribute(numberTargetAttribute)));
            });
        }

        /**
         * Lancé par la boucle d'animation dans son état final
         */
        function onEnd() {
            numbers.forEach(function (item) {
                item.textContent = thousands(item.getAttribute(numberTargetAttribute));
            });
        }

        /**
         * Calcule la position d'un élément par rapport au haut de la page.
         * @param {HTMLElement} element L'élément concerné
         * @returns {number} Le nombre de pixels entre l'élément et le haut de la balise <html>
         */
        function getOffsetTop(element) {
            var offsetTop = 0;
            if (element) {
                var currentOffsetElement = element;
                do {
                    if (!isNaN(currentOffsetElement.offsetTop)) {
                        offsetTop += currentOffsetElement.offsetTop;
                    }
                } while (currentOffsetElement = currentOffsetElement.offsetParent);
            }
            return offsetTop;
        }

        /**
         * Gaussienne de lissage de l'animation.
         * @param {number} t Valeur à lisser (0 <= t <= 1)
         * @returns {number} Valeur lissée (0 <= t <= 1)
         */
        function easeOutQuint(t) {
            return 1-(--t)*t*t*t;
        }

        /**
         * Ajoute les séparateurs de milliers au nombre à afficher.
         * @param {number} n Le nombre à formater
         * @returns {string} Le nombre formaté
         */
        function thousands(n) {
            if (n < 1000) {
                return n;
            }
            return thousands(Math.floor(n/1000)) + ' ' + threeDigits(n % 1000);
        }

        /**
         * Assure qu'un nombre sera affiché en 3 chiffres.
         * @param {number} n Le nombre à formater
         * @returns {string} Le nombre formaté
         */
        function threeDigits(n) {
            if (n < 10) {
                return '00' + n;
            } else if (n < 100) {
                return '0' + n;
            } else {
                return n;
            }
        }

        return init();
    };

    // On lie l'événement resize à la fonction de redimensionnement
    $(window).on('resize', UPVD.onResize);
    $(window).load(function () {
        UPVD.onInitialisation();
        UPVD.initialiserPausePlayCarrousel();
        $(window).scroll(UPVD.onWindowScroll);
        // Specific values for initialization
        $('.retour-haut').transition({
            y: "+=100",
            opacity: "0"
        }, function () {
            UPVD.handleButton();
        });
        // Triggered when 'retour-haut' button is clicked
        $('.retour-haut').click(function () {
            var ancreId = $(this).attr('href');
            var ancre = $(ancreId);
            $("html, body").animate({
                scrollTop: ancre.offset().top
            }, "slow");
            $(this).transition({
                y: "+=100",
                opacity: "0"
            });
            UPVD.isHeadPageVisible = false;
        });
        // Handle click and focusin events so it doesn't bubble
        // to the html element
        $('.plier-deplier-2__contenu').click(function (e) {
            e.stopPropagation();
        });
        $('.plier-deplier-2').focusin(function (e) {
            e.stopPropagation();
        });
        // Triggers the opening of submenu
        $('.plier-deplier-2__bouton')
            .click(
                function (e) {
                    if (!UPVD.auMoinsMedium()) {
                        e.stopPropagation();
                        var $this = $(this), $content = $this.closest('.plier-deplier-2').find('.plier-deplier-2__contenu');
                        if ($content.is('.plier-deplier-2__contenu--clos')) {
                            $content.removeClass('plier-deplier-2__contenu--clos').addClass('plier-deplier-2__contenu--ouvert');
                            $this.attr('aria-expanded', true);
                        } else {
                            $content.removeClass('plier-deplier-2__contenu--ouvert').addClass('plier-deplier-2__contenu--clos');
                            $this.attr('aria-expanded', false);
                        }
                    }
                });
        // Ouvertures des fenêtres de partage en popup
        $('a[data-open-popup]').click(
            function (e) {
                e.preventDefault();
                var href = $(this).attr('href');
                var popup = window.open(href, href, 'height=300,width=500');
                if (window.focus) {
                    popup.focus();
                }
                return false;
            });
		var scrollMarkers = [];
		[].forEach.call(document.querySelectorAll('[data-upvd-scroll-class]'), function (element) {
            var offsetTop = 0;
			if (element) {
                var currentOffsetElement = element;
				do {
					if (!isNaN(currentOffsetElement.offsetTop)) {
						offsetTop += currentOffsetElement.offsetTop;
					}
				} while (currentOffsetElement = currentOffsetElement.offsetParent);
			}
			scrollMarkers.push({
				marker: element,
				className: element.getAttribute('data-upvd-scroll-class'),
				offsetTop: offsetTop
			});
		});
        // Scrollspy
        var scrollSpyFix = 56;
        var bindScrollSpy = function () {
            scrollMarkers.forEach(function (marker) {
                var actualPosition = document.documentElement.scrollTop || document.body.scrollTop || 0;
                if (actualPosition > marker.offsetTop) {
                    document.body.classList.add(marker.className);
                } else {
                    document.body.classList.remove(marker.className);
                }
            });
            [].forEach.call(document.querySelectorAll('[data-scrollspy]'), function (element) {
                var $target = document.querySelector(element.getAttribute('data-scrollspy'));
                if (!!$target) {
                    var rect = $target.getBoundingClientRect();
                    if (rect.top <= scrollSpyFix && rect.bottom >= scrollSpyFix) {
                        if (!element.classList.contains('current')) {
                            element.classList.add('current');
                        }
                    } else {
                        if (element.classList.contains('current')) {
                            element.classList.remove('current');
                        }
                    }
                }
            });
        };
        window.addEventListener('scroll', UPVD.throttle(bindScrollSpy, 100));
        var menu = document.getElementById('menu');
        [].forEach.call(menu.querySelectorAll('[data-toggle-group="menus"],[data-toggle-group="menus-toggler"]'), function (element) {
            /** @var {HTMLLIElement} */
            var togglingElement = element.parentNode;
            togglingElement.addEventListener('toggle', function () {
                if (!UPVD.auMoinsMedium() && togglingElement.classList.contains('active')) {
                    var menuPrincipal = togglingElement.parentNode.parentNode;
                    menuPrincipal.scrollTop = togglingElement.offsetTop;
                }
            });
        });
    });
})(jQuery.noConflict());
