(function($, window) {
	var originalSize = parseFloat($('.contenu').css('font-size'));

	var minSize = originalSize;
	var maxSize = 30;

	var resizeText = function(multiplier) {

		var $el = $('.contenu');
		var coef = 1;

		if ($el.css('font-size') === '') {
			$el.css('font-size', '16px');
		}
		var newpx;
		if (multiplier != 0) {
			newpx = parseFloat($el.css('font-size')) + (multiplier * coef);
			if (newpx < minSize) {
				newpx = minSize;
			}
			if (newpx > maxSize) {
				newpx = maxSize;
			}
		} else {
			newpx = originalSize;
		}

		// (Pas de pattern MVC sur cette modification)
		if (newpx == minSize) {
			$('[data-role=zoom-out]').addClass('inactive');
		} else {
			$('[data-role=zoom-out]').removeClass('inactive');
		}

		if (newpx == maxSize) {
			$('[data-role=zoom-in]').addClass('inactive');
		} else {
			$('[data-role=zoom-in]').removeClass('inactive');
		}

		$el.css('font-size', newpx + 'px');
	};

	$(function() {
		$('[data-role=zoom-in]').click(function() {
			resizeText(1);
		});

		$('[data-role=zoom-out]').click(function() {
			resizeText(-1);
		});

		$('[data-role=zoom-reset]').click(function() {
			resizeText(0);
		});

	});
})(jQuery.noConflict(), window);