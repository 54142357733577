(function($) {
	/**
	 * Activation du menu principal, et gestion des clics
	 */
	UPVD.activerMenuPrincipal = function() {
		// Menu de navigation cliquable (dans tous les cas)
		var allContenu = $("#menu_principal > ul > li > div");
		$("#menu_principal > ul > li").each(function(index, item) {
			var itemJ = $(item);
			var contenu = $("> div", itemJ);
			$("> a, > em > a", itemJ).click(function(e) {
				// Comportement lien laissé par défaut s'il n'y a pas de
				// sous-menu
				if ($("> ul", contenu).length > 0) {
					e.preventDefault();
					e.stopPropagation();
				} else {
					return;
				}
				// Avant d'ouvrir, on désactive tous les contenus ouverts
				if (contenu.is(':hidden')) {
					allContenu.hide();
					contenu.show();
					contenu.addClass("active");
				} else {
					allContenu.hide();
					allContenu.removeClass("active");
				}
			});

			$('html').click(function() {
				allContenu.hide();
			});
			$('html').focusin(function() {
				allContenu.hide();
			});
		});
	}
	$(document).ready(function() {
		UPVD.activerMenuPrincipal();
	});
})(jQuery.noConflict());
